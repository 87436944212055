// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fotoHeader.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.feed {
    color: black !important;
    overflow-x: hidden;
}

#title{
    font-size: 2.5rem;
    font-weight: 500;
}   

#subtitle{
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
}

.Header{
    padding: 2rem;
    align-items: center;
    text-align: left;
    height: 100%;
    object-fit: cover;
}


#boxHeader {
    position: relative;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: calc(100vw * (602 / 1915));
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
}

#titles{
    margin-bottom: 2rem;
    text-align: left;
}


@media screen and (max-width: 768px) {

    #boxHeader{
        height: 40vh;
        background-size: cover;
    }

    #titles{
        text-align: center;
    }
}

    
    `, "",{"version":3,"sources":["webpack://./src/components/Feed.css"],"names":[],"mappings":";;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;;AAGA;IACI,kBAAkB;IAClB,yDAA+C;IAC/C,2BAA2B;IAC3B,wBAAwB;IACxB,4BAA4B;IAC5B,YAAY;IACZ,kCAAkC;IAClC,SAAS;IACT,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;;IAEI;QACI,YAAY;QACZ,sBAAsB;IAC1B;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["\n\n.feed {\n    color: black !important;\n    overflow-x: hidden;\n}\n\n#title{\n    font-size: 2.5rem;\n    font-weight: 500;\n}   \n\n#subtitle{\n    font-size: 2rem;\n    font-weight: 300;\n    line-height: 2.5rem;\n    margin-bottom: 1.5rem;\n}\n\n.Header{\n    padding: 2rem;\n    align-items: center;\n    text-align: left;\n    height: 100%;\n    object-fit: cover;\n}\n\n\n#boxHeader {\n    position: relative;\n    background-image: url(\"/public/fotoHeader.jpg\");\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n    width: 100vw;\n    height: calc(100vw * (602 / 1915));\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    align-items: end;\n    color: white;\n}\n\n#titles{\n    margin-bottom: 2rem;\n    text-align: left;\n}\n\n\n@media screen and (max-width: 768px) {\n\n    #boxHeader{\n        height: 40vh;\n        background-size: cover;\n    }\n\n    #titles{\n        text-align: center;\n    }\n}\n\n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
